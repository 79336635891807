<template>
  <div :class="classes">
    <PrimitiveButton
      :id="`${kebabCaseTitle}-accordion-header`"
      :aria-controls="`${kebabCaseTitle}-accordion-body`"
      :aria-expanded="isOpen.toString()"
      :label="`Go to ${title}`"
      class="CoreFooterAccordion__switch"
      @action="buttonClick"
    >
      <span class="CoreFooterAccordion__inner t-h4">
        {{ title }}

        <span class="CoreFooterAccordion__icon">
          <span>
            <StyleCircle>
              <UIIndicator :is-open="isOpen" speed="slow" />
            </StyleCircle>
          </span>
        </span>
      </span>
    </PrimitiveButton>

    <div
      :id="`${kebabCaseTitle}-menu-accordion-body`"
      ref="drawerBody"
      role="region"
      class="CoreFooterAccordion__body"
    >
      <div ref="drawerContent" class="CoreFooterAccordion__content">
        <ul class="CoreFooterAccordion__list">
          <li
            v-for="(link, linkIndex) in links"
            :key="`sectionLink-${linkIndex}`"
          >
            <PrimitiveLink
              class="CoreFooterAccordion__link t-para"
              :link="link"
            >
              {{ link.linkText }}
            </PrimitiveLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import kebabCase from 'lodash-es/kebabCase'
import breakpoint from '@/legacy/mixins/breakpoint'
import PrimitiveButton from '@/components/vue/Primitive/Button.vue'
import StyleCircle from '@/components/vue/Style/Circle.vue'
import UIIndicator from '@/components/vue/UI/Indicator.vue'
import PrimitiveLink from '@/components/vue/Primitive/Link.vue'

export default {
  name: 'CoreFooterAccordion',

  components: {
    PrimitiveButton,
    StyleCircle,
    UIIndicator,
    PrimitiveLink,
  },

  mixins: [breakpoint],

  props: {
    title: {
      default: '',
      type: String,
    },

    links: {
      default: () => [],
      type: Array,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  computed: {
    classes() {
      return {
        CoreFooterAccordion: true,
        'is-open': this.isOpen,
      }
    },

    kebabCaseTitle() {
      return kebabCase(this.title)
    },
  },

  methods: {
    buttonClick() {
      if (!this.breakpoint.sm.matches) this.toggleAccordion()
    },

    toggleAccordion() {
      this.isOpen = !this.isOpen

      const { drawerBody, drawerContent } = this.$refs
      const range = {
        open: `${drawerContent.clientHeight}px`,
        close: 0,
      }

      if (this.drawerBodyTl) this.drawerBodyTl.kill()
      if (this.drawerContentTl) this.drawerContentTl.kill()

      this.drawerBodyTl = gsap.timeline({
        defaults: {
          duration: 0.5,
          ease: 'power3.inOut',
        },
        onComplete: () => {
          if (this.isOpen) drawerBody.style.height = 'auto'
          else drawerBody.style.height = null
        },
      })
      this.drawerBodyTl.fromTo(
        drawerBody,
        {
          height: this.isOpen ? range.close : range.open,
        },
        {
          height: this.isOpen ? range.open : range.close,
        },
      )

      this.drawerContentTl = gsap.timeline({
        defaults: {
          duration: this.isOpen ? 0.5 : 0.25,
          ease: 'power2.out',
          delay: this.isOpen ? 0.25 : 0,
        },
        onComplete: () => {
          if (!this.isOpen) drawerContent.style.opacity = null
        },
      })
      this.drawerContentTl.fromTo(
        drawerContent,
        {
          opacity: this.isOpen ? 0 : 1,
        },
        {
          opacity: this.isOpen ? 1 : 0,
        },
      )
    },
  },
}
</script>

<style lang="postcss">
.CoreFooterAccordion__switch {
  @screen smMax {
    width: 100%;
    padding: calc(theme('spacing.5') / 2) 0;
  }

  @screen sm {
    cursor: default;
  }
}

.CoreFooterAccordion__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CoreFooterAccordion__icon {
  @apply ml-1;

  @screen sm {
    display: none;
  }
}

.CoreFooterAccordion__body {
  @screen smMax {
    position: relative;
    height: 0;
    overflow: hidden;
  }

  @screen sm {
    .CoreFooterAccordion__switch + & {
      @apply mt-1;
    }
  }
}

.CoreFooterAccordion__content {
  @screen smMax {
    opacity: 0;

    @screen sm {
      opacity: 1;
    }
  }
}

.CoreFooterAccordion__list {
  @apply -ml-1
    pb-2;
}

.CoreFooterAccordion__link {
  @apply p-1
    transition-opacity
    duration-250
    ease-smooth;

  display: block;

  .CoreFooterAccordion__list:hover &:not(:hover) {
    opacity: 0.3;
  }
}
</style>
