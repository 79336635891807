<template>
  <ButtonStadium
    stadium-size="small"
    stadium-color="white"
    label="Open location selector"
    :button-text="buttonText"
    @action="openLocalePrompt"
  />
</template>

<script>
import { SELECTOR_MODAL_KEY } from '@/components/vue/Drawers/LocaleSelectorDrawer.vue'
import ButtonStadium from '@/components/vue/Button/Stadium.vue'

export default {
  components: { ButtonStadium },

  inject: ['currentLocaleConfig'],

  computed: {
    buttonText() {
      return `${this.currentLocaleConfig.emoji} ${
        this.currentLocaleConfig.localisedName || this.currentLocaleConfig.name
      }`
    },
  },

  methods: {
    openLocalePrompt() {
      this.$store.dispatch('globals/registerModalToOpen', SELECTOR_MODAL_KEY)
    },
  },
}
</script>
