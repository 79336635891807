<template>
  <PrimitiveLink>
    <StyleCircle v-bind="{ ...$attrs, class: '' }">
      <Icon v-bind="{ ...$attrs, class: '' }" />
    </StyleCircle>
  </PrimitiveLink>
</template>

<script>
import PrimitiveLink from '@/components/vue/Primitive/Link.vue'
import StyleCircle from '@/components/vue/Style/Circle.vue'
import Icon from '@/components/vue/Icon/index.vue'

export default {
  name: 'LinkCircleIcon',

  components: {
    PrimitiveLink,
    StyleCircle,
    Icon,
  },
}
</script>
