<template>
  <div :class="classes">
    <ul class="CoreSocial__list">
      <li v-for="(link, index) in links" :key="index">
        <LinkCircleIcon
          class="CoreSocial__link"
          :label="`Follow us on ${link.type}`"
          :icon="link.type"
          :circle-color="link.type"
          circle-size="x-large"
          :link="{
            isExternal: true,
            url: link.url,
            target: '_blank',
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import LinkCircleIcon from '@/components/vue/Link/CircleIcon.vue'

const linkMap = [
  {
    type: 'instagram',
    url: {
      default: 'https://www.instagram.com/wildrefill',
      de: 'https://www.instagram.com/wildrefill_de/',
      fr: 'https://www.instagram.com/wildrefill_fr/',
    },
  },
  {
    type: 'twitter',
    url: {
      default: 'https://www.twitter.com/wildrefill',
    },
  },
  {
    type: 'facebook',
    url: {
      default: 'https://www.facebook.com/wildrefill',
      de: 'https://www.facebook.com/WildRefillDE',
    },
  },
  {
    type: 'tiktok',
    url: {
      default: 'https://www.tiktok.com/@wildrefill',
      fr: 'https://www.tiktok.com/@wildrefill?lang=fr',
    },
  },
]

export default {
  name: 'CoreSocial',

  components: {
    LinkCircleIcon,
  },

  inject: ['currentLocaleConfig'],

  computed: {
    classes() {
      return {
        CoreSocial: true,
      }
    },

    links() {
      return linkMap.reduce((allLinks, currentLink) => {
        const { url, type } = currentLink

        // Defend against malformed data
        if (!url || !type) return allLinks

        // Localise URL if available
        const localisedUrl = url[this.currentLocaleConfig.code] || url.default

        return [
          ...allLinks,
          {
            type,
            url: localisedUrl,
          },
        ]
      }, [])
    },
  },
}
</script>

<style lang="postcss">
.CoreSocial {
  display: inline-block;
}

.CoreSocial__list {
  @apply -m-1;

  display: flex;
}

.CoreSocial__link {
  @apply p-1;
}
</style>
