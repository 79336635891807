<template>
  <div :class="classes">
    <div class="CoreFooter__upper">
      <LayoutWrapper>
        <LayoutRow>
          <LayoutRowItem
            v-if="footerFlags.enableNewsletterSignup"
            width="full"
            :width-media="{
              md: '1/2',
            }"
          >
            <div class="CoreFooter__newsletter">
              <div class="CoreFooter__form">
                <ThirdPartyKlaviyoNewsletter />
              </div>

              <div
                v-if="footerFlags.enableSocialLinks"
                class="CoreFooter__social"
              >
                <CoreSocial />
              </div>
            </div>
          </LayoutRowItem>

          <LayoutRowItem
            v-for="(section, sectionIndex) in footerMenus"
            :key="`section-${sectionIndex}`"
            class="CoreFooter__item"
            width="full"
            :width-media="{
              sm: '4/12',
              md: '2/12',
            }"
          >
            <CoreFooterAccordion
              class="CoreFooter__accordion"
              :title="section.title"
              :links="section.links"
            />
          </LayoutRowItem>
        </LayoutRow>
        <PrimitiveLink class="CoreFooter__meta-cert" :link="bCorpLink">
          <img
            :srcset="`${$imgix.getSize(
              '/imgs/Certified-B-Corporation-logo.png',
              { w: '57px' },
            )} 57w,
                      ${$imgix.getSize(
                        '/imgs/Certified-B-Corporation-logo.png',
                        { w: '71px' },
                      )} 71w,
                      ${$imgix.getSize(
                        '/imgs/Certified-B-Corporation-logo.png',
                        { w: '142px' },
                      )} 142w`"
            sizes="
              (min-width: 799px) 71px,
              57px
            "
            alt="Certified B-Corporation"
            width="71"
            height="120"
            loading="lazy"
          />
        </PrimitiveLink>
      </LayoutWrapper>
    </div>

    <UITicker
      v-if="
        footerFlags.enableFooterTicker && tickerWords && tickerWords.length > 0
      "
      class="CoreFooter__ticker t-h1"
      spacing="12"
      :speed="20"
      :items="tickerWords"
      type="words"
    />

    <div class="CoreFooter__lower">
      <LayoutWrapper>
        <div class="CoreFooter__meta-container">
          <div class="CoreFooter__locale-button">
            <CoreFooterLocaleButton />
          </div>
          <span
            class="CoreFooter__meta-link CoreFooter__meta-link--copyright t-h6"
          >
            {{ getCopyrightText }}
          </span>

          <ul class="CoreFooter__meta-list t-h6">
            <li
              v-for="(link, linkIndex) in footerLowerMenu"
              :key="`metaLink-${linkIndex}`"
            >
              <PrimitiveLink class="CoreFooter__meta-link" :link="link">
                {{ link.linkText }}
              </PrimitiveLink>
            </li>
          </ul>
        </div>
      </LayoutWrapper>
    </div>
  </div>
</template>

<script>
import { mapLinksAndControls } from '@/legacy/helpers/cms'
import LayoutWrapper from '@/components/vue/Layout/Wrapper.vue'
import LayoutRow from '@/components/vue/Layout/Row.vue'
import LayoutRowItem from '@/components/vue/Layout/RowItem.vue'
import ThirdPartyKlaviyoNewsletter from '@/components/vue/ThirdParty/KlaviyoNewsletter.vue'
import CoreSocial from '@/components/vue/Core/Social.vue'
import CoreFooterAccordion from '@/components/vue/Core/Footer/Accordion.vue'
import PrimitiveLink from '@/components/vue/Primitive/Link.vue'
import UITicker from '@/components/vue/UI/Ticker.vue'
import CoreFooterLocaleButton from '@/components/vue/Core/Footer/LocaleButton.vue'

export default {
  name: 'CoreFooter',

  components: {
    LayoutWrapper,
    LayoutRow,
    LayoutRowItem,
    ThirdPartyKlaviyoNewsletter,
    CoreSocial,
    CoreFooterAccordion,
    PrimitiveLink,
    UITicker,
    CoreFooterLocaleButton,
  },

  provide() {
    return {
      microcopies: this.microcopies,
      currentLocaleConfig: this.locale,
    }
  },

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    microcopies: {
      type: Object,
      default: () => ({}),
    },
    locale: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      bCorpLink: {
        isExternal: false,
        link: {
          slug: '/pages/bcorp',
        },
        label: '/pages/bcorp link',
      },
    }
  },
  computed: {
    footerFlags() {
      const { enableFooterTicker, enableNewsletterSignup, enableSocialLinks } =
        this.content

      return {
        enableFooterTicker,
        enableNewsletterSignup,
        enableSocialLinks,
      }
    },
    tickerWords() {
      const { tickerWords } = this.content

      return tickerWords ? tickerWords.split('\n') : null
    },
    footerMenus() {
      const { footerNavMenus: menus } = this.content

      return this.mapFooterMenus(menus)
    },
    footerLowerMenu() {
      const { footerLowerNavMenu: menu } = this.content

      const lowerMenusArray = this.mapFooterMenus([menu])
      return lowerMenusArray[0] && lowerMenusArray[0].links
        ? lowerMenusArray[0].links
        : null
    },
    classes() {
      return {
        CoreFooter: true,
      }
    },
    getCopyrightText() {
      const date = new Date().getFullYear()
      return `©Wild UK ${date}`
    },
  },
  methods: {
    mapFooterMenus(menus) {
      if (!menus || menus.length === 0) {
        return []
      }
      return menus.reduce((allMenus, menu) => {
        if (!menu) {
          return allMenus
        }
        const { heading, items } = menu
        return [
          ...allMenus,
          {
            title: heading || '',
            links: items ? items.map(mapLinksAndControls) : [],
          },
        ]
      }, [])
    },
  },
}
</script>

<style lang="postcss">
.CoreFooter {
  @apply bg-yellow
    pt-9
    pb-4;

  @screen sm {
    @apply pt-12
      pb-4;
  }
}

.CoreFooter__newsletter {
  @screen smMax {
    text-align: center;
  }
}

.CoreFooter__form {
  width: 100%;
  max-width: 416px;

  @screen smMax {
    margin-right: auto;
    margin-left: auto;
  }

  .CoreFooter__title + & {
    @apply mt-4;

    @screen sm {
      @apply mt-5;
    }
  }
}

.CoreFooter__social {
  .CoreFooter__form + & {
    @apply mt-4;

    @screen sm {
      @apply mt-5;
    }
  }
}

.CoreFooter__accordion {
  @screen smMax {
    .CoreFooter__item + .CoreFooter__item & {
      @apply border-t
        border-solid
        border-offblack
        border-opacity-10;
    }
  }
}

.CoreFooter__ticker {
  line-height: 1.2;

  .CoreFooter__upper + & {
    @apply pt-6;

    @screen sm {
      @apply pt-12;
    }
  }
}

.CoreFooter__lower {
  .CoreFooter__ticker + & {
    @apply pt-9;

    @screen sm {
      @apply pt-10;
    }
  }
}

.CoreFooter__meta-container {
  text-align: center;

  @screen sm {
    display: flex;
    align-items: center;
  }
}

.CoreFooter__meta-list {
  @screen sm {
    display: flex;
  }
}

.CoreFooter__meta-link {
  @apply transition-opacity
    duration-250
    ease-smooth;

  display: block;
  padding: calc(theme('spacing.3') / 2);

  @screen sm {
    padding: calc(theme('spacing.5') / 2);
  }

  &--copyright {
    @screen smMax {
      padding-top: 0;
    }
  }

  .CoreFooter__meta-list:hover &:not(:hover) {
    opacity: 0.3;
  }
}

.CoreFooter__locale-button {
  @screen smMax {
    @apply mb-4;
  }
}

.CoreFooter__meta-cert {
  display: block;
  width: 57px;
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;

  @screen sm {
    width: 71px;
    margin-top: -35px;
    margin-right: 0;
    margin-left: auto;
  }
}
</style>
